import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const loading = `
      <div class="modal-body">
        <div class="d-flex justify-content-center py-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    `

    document.querySelector('#noteModal.modal').addEventListener('hidden.bs.modal', function(event) {
      event.target.querySelector('.modal-content').innerHTML = loading
    });
  }
}
