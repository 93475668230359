import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["monthly", "annual", "start", "end", "monthlySubmit", "year", "annualSubmit"]

  connect() {
    const loading = `
      <div class="modal-body">
        <div class="d-flex justify-content-center py-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    `

    document.querySelectorAll('.modal').forEach(function (modal) {
      modal.addEventListener('hidden.bs.modal', function(event) {
        event.target.querySelector('.modal-content').innerHTML = loading
      });
    });
  }

  selectMonthly() {
    this.monthlyTarget.click()
    this.startTarget.disabled = false
    this.endTarget.disabled = false
    this.monthlySubmitTarget.disabled = false
    this.yearTarget.disabled = true
    this.annualSubmitTarget.disabled = true
  }

  selectAnnual() {
    this.annualTarget.click()
    this.startTarget.disabled = true
    this.endTarget.disabled = true
    this.monthlySubmitTarget.disabled = true
    this.yearTarget.disabled = false
    this.annualSubmitTarget.disabled = false
  }
}
