import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    const openHandler = function (event) {
      if (event.target.classList.contains('loaded')) {
        return false
      }
      Rails.ajax({
        type: "get",
        dataType: "script",
        url: event.target.dataset.url,
        error: function () {
          alert('エラーが発生しました。')
        }
      })
    };

    document.querySelectorAll('.accordion-collapse').forEach(function (button) {
      button.addEventListener('shown.bs.collapse', openHandler);
    });

    document.querySelector('.accordion-button').click()
  }
}
