// レポート画面で「エリア」・「発電所」が選択された時に「一括公開設定」ボタンがdisabledになる
document.addEventListener('DOMContentLoaded', () => {
  const areaSelect = document.getElementById('area-select');
  const powerPlantSelect = document.getElementById('power-plant-select');
  const bulkReleaseButton = document.getElementById('bulk-release-button');

  // 変更を監視する関数
  const observeSelectChanges = (select) => {
    // 新しいMutationObserverを作成する
    const observer = new MutationObserver((mutationsList) => {
      // 選択の属性または子ノードに変更があるかどうかを確認する
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' || mutation.type === 'childList') {
          // 変更が検出された場合はupdateButtonStateを呼び出す
          updateButtonState();
          break; // 変更が検出された後、ループを終了する
        }
      }
    });

    // 選択肢の属性や子ノードの変更を監視する
    observer.observe(select, { attributes: true, childList: true });
  };

  // ボタンの状態を更新する関数
  const updateButtonState = () => {
    if (areaSelect.value !== '' || powerPlantSelect.value !== '') {
      bulkReleaseButton.disabled = true;
    } else {
      bulkReleaseButton.disabled = false;
    }
  };

  // エリアと発電所の選択肢の変更を監視する
  observeSelectChanges(areaSelect);
  observeSelectChanges(powerPlantSelect);

  // 初期チェック
  updateButtonState();
});
