document.addEventListener('turbolinks:load', () => {
  const initializeColumnVisibility = () => {
    const checkboxes = document.querySelectorAll('.column-toggle');
    const hiddenColumns = Array.from(checkboxes)
      .filter(checkbox => !checkbox.checked)
      .map(checkbox => checkbox.id.replace('column_', ''));
    localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumns));
    applyColumnVisibility();
  };

  const toggleColumns = () => {
    const checkboxes = document.querySelectorAll('.column-toggle');
    const hiddenColumns = Array.from(checkboxes)
      .filter(checkbox => !checkbox.checked)
      .map(checkbox => checkbox.id.replace('column_', ''));
    localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumns));
    applyColumnVisibility();
  };

  const applyColumnVisibility = () => {
    const hiddenColumns = JSON.parse(localStorage.getItem('hiddenColumns') || '[]');
    const tables = document.querySelectorAll('.accordion-body table');
    tables.forEach(table => {
      const columns = table.querySelectorAll('th, td');
      columns.forEach(cell => {
        const columnName = Array.from(cell.classList).find(className =>
          className !== 'align-middle' && className !== 'power_plant' && className !== 'voltage-rank'
        );
        if (columnName) {
          cell.style.display = hiddenColumns.includes(columnName) ? 'none' : '';
        }
      });
    });
  };

  initializeColumnVisibility();

  document.querySelectorAll('.column-toggle').forEach(checkbox => {
    checkbox.addEventListener('change', toggleColumns);
  });

  const accordionObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const accordionBody = mutation.target.querySelector('.accordion-body');
        if (accordionBody && !accordionBody.classList.contains('loading')) {
          applyColumnVisibility();
        }
      }
    });
  });

  document.querySelectorAll('.accordion-collapse').forEach(accordionItem => {
    accordionObserver.observe(accordionItem, { attributes: true, subtree: true, childList: true });
  });
});
