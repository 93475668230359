import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'powerPlantId', 'checkBox']

  setParams(e){
    // フォームで選択した、開始日、終了日、発電所IDを取得する
    const startDate = this.startDateTarget.value
    const endDate = this.endDateTarget.value
    const powerPlantId = this.powerPlantIdTarget.value

    // Ajax送信用のURLのパラメータを空にする (2回目以降のパラメータ重複を避けるため)
    const url = new URL(e.target.href);
    url.search = '';

    // 送信先URLにフォームの値をパラメータとして追加する
    e.target.href = url.toString() + `?start_date=${startDate}&end_date=${endDate}&power_plant_id=${powerPlantId}`
  }

  submit() {
    // 項目選択において、チェックされている項目名を配列で取得する
    const checkBoxes = this.checkBoxTargets
    var checkedHeader = []
    checkBoxes.forEach(function(checkBox) {
      if (checkBox.checked) {
        checkedHeader.push(checkBox.name)
      }
    });

    // hiddenタグに項目名群をカンマ区切りで格納する
    document.getElementById('hiddenInput').value = checkedHeader

    // 送信ボタンをクリック
    document.getElementById('submitButton').click()
  }
}
