// NOW: 使用してないコード(修正で再度使用する可能性があるので残しておく)
// 使用する場合↓↓
// tbody id="sortable"を付与して使用する
// tr id="power-plant-#{power_plant.id}" class="item" data-model-name="#{power_plant.class.name.underscore}" data-update-url="/settings/power_plants/#{power_plant.id}/sort"


// JQueryのsortableで発電所一覧をドラッグ&ドロップで並び替える
// REF: https://qiita.com/fumikao/items/77ffdffc24a9a814fad7
$(document).on('turbolinks:load', function() {
  // デフォルトだとドラッグ中の横幅が狭まるので以下のURLを参考に調整
  // REF: https://qiita.com/qwe001/items/10366df1901853acca5c#%E5%AE%9F%E8%A3%85
  function fixPlaceHolderWidth(event, ui){
    ui.children().each(function(){
        $(this).width($(this).width());
    });
    return ui;
  };  

  // 実際に並び替えを行う処理
  $('#sortable').sortable({
    // 上の(サイズを縮小させないための)関数を実行
    helper: fixPlaceHolderWidth,

    update: function(e, ui){
      let item = ui.item;
      let item_data = item.data();
      let params = {_method: 'put'};
      params[item_data.modelName] = { row_order_position: item.index() }
      $.ajax({
        type: 'POST',
        url: item_data.updateUrl,
        dataType: 'json',
        data: params
      });
    }
  });

});