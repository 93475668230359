import Chart from "stimulus-chartjs"

export default class extends Chart {
  connect() {
    super.connect()
    // 発電所のメモを配列で取得
    let memos = this.chart.tooltip._data.memos;

    // タイトル（発電所名）を設定する関数定義
    let setTitle = function(tooltipItem, data){
                      var power_plant_name = tooltipItem[0].xLabel;
                      if (Array.isArray(power_plant_name)) {
                        power_plant_name = power_plant_name.join("");
                      }
                      return power_plant_name;
                   }

    // メモを追加する関数定義
    let addMemo = function(tooltipItem, data) {
                    var name_count = getNameCount(tooltipItem[0].xLabel);
                    if (name_count < 14) {
                      name_count = 14
                    }

                    var memo = memos[tooltipItem[0].index];
                    if (memo) {
                      memo = splitByLength(memo, name_count);
                    }
                    return memo;
                  }

    // 別途タイトル（発電所名）を設定
    // デフォルトだと名前が長い場合は配列で分割されているため
    this.chart.tooltip._options.callbacks.title = setTitle;
    // ツール設定を追加
    this.chart.tooltip._options.callbacks.footer = addMemo;

    // 改行するには配列にする必要があるため、引数の数字で文字列を分割して配列を作成
    function splitByLength(str, length) {
      var resultArr = [];
      if (!str || !length || length < 1) {
          return resultArr;
      }
      var index = 0;
      var start = index;
      var end = start + length;
      while (start < str.length) {
          resultArr[index] = str.substring(start, end);
          index++;
          start = end;
          end = start + length;
      }
      return resultArr;
    
    }

    // 発電所名の文字サイズを取得
    function getNameCount(name) {
      // 発電所名が配列になっていたら
      if (Array.isArray(name)) {
        name = name.join("");
      }
      return name.length;
    }

  }
}