import { Controller } from "stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {
  crawle(event) {
    event.preventDefault()
    // 登録ボタンを押せなくする
    document.querySelector('#js-submit').disabled = true
    // テストボタンをメッセージ付きで押せなくする
    const loading = `
      <div class="spinner-border spinner-border-sm text-white" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span class="ms-2">テスト中...</span>
    `
    document.querySelector('#js-test-button').innerHTML = loading
    document.querySelector('#js-test-button').disabled = true
    // 結果をクリアする
    document.querySelector('#js-results').innerHTML = ''
    // Ajaxでクローリングをテストする
    Rails.ajax({
      type: "post",
      dataType: "json",
      url: this.data.get("url"),
      data: new FormData(this.element),
      success: this.handleSuccess,
      error: this.handleError
    })
  }

  handleSuccess(data) {
    console.log(data)
    let messages = `
      <p class="text-success text-center fw-bold">テストが成功しました。</p>
      <div class="border p-2">
        <ul class="d-flex flex-column align-items-center mb-0">
          <li>テストは成功しましたが、実際に取得される値が問題ないか確認してください</li>
        </ul>
      </div>
    `
    
    // ストリング監視のテスト結果の場合のみ有効
    if (typeof data['strings_count'] !== 'undefined') {
      document.querySelector('#string_crawling_item_strings_count').value = data['strings_count']
      document.querySelector('#string_crawling_item_strings_count').disabled = false
      document.querySelector('#js-strings-name-list').innerHTML = data['strings_name_list']
      document.querySelector('#js-strings-name-list-label').innerHTML = '取得一覧'
    }

    document.querySelector('#js-results').innerHTML = messages
    document.querySelector('#js-test-button').disabled = false
    document.querySelector('#js-test-button').innerHTML = '上記の内容でクローラーのテストを実行する'
    document.querySelector('#js-submit').disabled = false
  }

  handleError() {
    let messages = `
      <p class="text-danger text-center fw-bold">テストが失敗しました。以下をご確認ください。</p>
      <div class="border border-danger p-2">
        <ul class="d-flex flex-column align-items-center mb-0">
          <li>入力内容をご確認ください</li>
          <li>深夜0時付近だと発電量が取得できない場合があります</li>
          <li>上記以外で原因不明の場合は、開発元に問い合わせください</li>
        </ul>
      </div>
    `
    document.querySelector('#js-results').innerHTML = messages
    document.querySelector('#js-test-button').disabled = false
    document.querySelector('#js-test-button').innerHTML = '上記の内容でクローラーのテストを実行する'
    document.querySelector('#js-submit').disabled = true
  }
}
