// select2導入
$(document).on('turbolinks:load', function() {
    // select2利用のメソッド
    $('.select2').select2({
      theme: "bootstrap",
      width: "100%"
    });
});

// 係数比較のセレクトボックス(エリア絞り込みと発電所名絞り込み)の連動処理
$(document).on('turbolinks:load', function() {

    // 発電所プルダウンのイベント発火時の処理
    $('.js-power-plant-pulldown').change(function(){
      let input = $(this).val();
      
      // 「すべて」を選択した場合
      if (input === ''){
        $('.js-area-pulldown').val('');

      // 特定の発電所を選択した場合
      } else {
        $.ajax({
          type: 'GET',
          url: 'factor/get_area_from_power_plant_id',
          data: {keyword: input},
          dataType: 'json'
        })
        .done(function(area_id){
          // 特定した発電所に属するエリアを.js-area-selectのセレクトボックス内で指定する
          $('.js-area-pulldown').val(area_id);
        })
      }
  
    });
  
    // エリアプルダウンのイベント発火時の処理
    $('.js-area-pulldown').change(function(){
      let input = $(this).val();
  
      $.ajax({
        type: 'GET',
        url: 'factor/get_power_plants_from_area_id',
        data: {keyword: input},
        dataType: 'json'
      })
      .done(function(data){
        
        // 既存の発電所プルダウンをクリア
        $('.js-power-plant-pulldown').children().remove();
        // promptを追加
        $('.js-power-plant-pulldown').append($('<option>').attr({value: ''}).text('すべて'));

        // エリアをoptgroup、全発電所をoptionにそれぞれ設定
        $.each(data, function(area_name, power_plants){
          // optgroup(エリア)を設定
          $('.js-power-plant-pulldown').append($('<optgroup>', {label: area_name}));
          $.each(power_plants, function(index, value){
            // option(発電所)を設定
            $('.js-power-plant-pulldown').append($('<option>').attr({value: value[1]}).text(value[0]));
          })
        })

      })

    });
  });