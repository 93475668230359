// NOTE: 各エリアにおける基準発電所をCRUDするためのコントローラー (settings/areas#editを使用)

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['potentialCoef']

  connect() {
  } 

  validate(event) {
    // 既存のバリデーションメッセージを削除
    var elements = document.getElementsByClassName("potential-coef-validation-msg");
    while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
    }    

    // バリデーション
    let coefs = this.potentialCoefTargets;
    for (const coef of coefs) {
      if (coef.value === '') {
        coef.parentNode.insertBefore(this.validationErrorMsg(), coef);

        // ページのトップへ移動
        window.scrollTo({
          top: coef.offsetTop - 300,
          behavior: 'smooth'
        });        

        // ページ遷移させないようにする
        event.preventDefault();
        
        break;
      }
    }
  }

  // バリデーションエラーメッセージを表示
  validationErrorMsg() {
    const newElement = document.createElement("p");
    newElement.textContent = "ポテンシャル係数を入力してください。";
    newElement.classList.add('potential-coef-validation-msg');
    newElement.classList.add('text-danger');
    newElement.classList.add('mb-0');
    
    return newElement;
  }
}
