import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const modal = document.getElementById('commentModal')
    modal.addEventListener('show.bs.modal', (event) => {
      const button = event.relatedTarget
      const powerPlantId = button.getAttribute('data-power-plant-id')
      this.element.action = this.element.action.replace(/\/\d+\//, `/${powerPlantId}/`)
    })
  }
}
