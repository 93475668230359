// NOTE: 各エリアにおける基準発電所をCRUDするためのコントローラー (settings/areas#editを使用)

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fieldsBox']

  connect() {
  }

  // フィールドを追加
  addField(e) {
    // フィールドを複製する
    let field = e.target.parentNode.cloneNode(true);
    // プルダウンを未選択状態にする
    field.childNodes[0].value = 0;
    // フィールドの箱の子要素として最後に追加する
    this.fieldsBoxTarget.appendChild(field);
  }

  // フィールドを削除
  deleteField(e) {
    // フィールド数が1つの場合は、フィールドを削除せずにセレクトボックスを未選択状態にする
    if (this.fieldsBoxTarget.childElementCount === 1) {
      let pulldown = e.target.parentNode.childNodes[0];
      pulldown.value = 0;
    } else {
      // フィールドを削除
      e.target.parentNode.remove();
    }
  }  
}
